import React from 'react'
import "./MobileLocation.css"
import '../../../styles/typography.css'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'

function MobileLocation() {
  return (
    <div className='mobile-location-container'>
        <span className='mobile-h3-black' style={{marginLeft: 20}}>
        Как добраться
        </span>
        <div className='mobile-location-body'>
            <div className='mobile-location-piece'>
                <span className='mobile-text-3-black'>
                Горячая линия
                </span>
                <span className='mobile-text-2-black'>
                +7 (922) 604 33 09
                </span>
            </div>
            <div className='mobile-location-horizontal'>
                <div className='mobile-location-piece'>
                    <span className='mobile-text-3-black'>
                    Адрес
                    </span>
                    <span className='mobile-text-2-black'>
                    ул. Крылова, 27<br />деловой центр "Крыловский"<br /> напротив Екатеринбург Арена
                    </span>
                </div>
                <div className='mobile-location-piece'>
                    <span className='mobile-text-3-black'>
                    График работы
                    </span>
                    <span className='mobile-text-2-black'>
                    ПН-СБ с 10:00 до 20:00<br /> ВС выходной
                    </span>
                </div>
            </div>
        </div>
        <div className='mobile-map-container'>
        <YMaps>
            <Map defaultState={{ center: [56.83227, 60.571037], zoom: 15 }} width="100%" height="250px">
            <Placemark geometry={[56.83227, 60.571037]} />
            </Map>
        </YMaps>
        </div>
    </div>
  )
}

export default MobileLocation
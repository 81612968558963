import React from 'react'
import "./MobileOurActions.css"
import '../../../styles/typography.css'

function MobileOurActions() {
  return (
    <div className='mobile-our-actions-container'>
        <span className='mobile-h3-black'>
        Как будем действовать
        </span>
        <div className='mobile-our-actions-body'>
            <div className='mobile-our-actions-line'></div>
            <span className='mobile-text-2-black'>
            Приносите устройство в наш сервис на Крылова, 27.<br /> Не можете лично? Примем доставку курьером яндекса. Из другого города принимаем посылки СДЭК.
            </span>
            <div className='mobile-our-actions-line'></div>
            <span className='mobile-text-2-black'>
            Мы проводим диагностику вашего устройства.
            </span>
            <div className='mobile-our-actions-line'></div>
            <span className='mobile-text-2-black'>
            Вместе обсуждаем варианты устранения неполадки, сроки и стоимость работы.
            </span>
            <div className='mobile-our-actions-line'></div>
            <span className='mobile-text-2-black'>
            Убедитесь, что помните свой пароль Apple ID. Если на компьютере активна функция File Vault, подготовьте пароль шифрования жёсткого диска.
            </span>
            <div className='mobile-our-actions-line'></div>
            <span className='mobile-text-2-black'>
            Вы можете сократить время обслуживания, если распечатаете и заранее заполните нашу анкету.
            </span>
            <a href='/pdf/questionnaire.pdf' className='mobile-button-black' target='_blank' rel='noopener noreferrer'>
            Скачать анкету
            </a>
            <div className='mobile-our-actions-line'></div>
        </div>
    </div>
  )
}

export default MobileOurActions
import React from 'react'
import "./MobileGuarantee.css"
import '../../../styles/typography.css'

function MobileGuarantee() {
  return (
    <div className='mobile-guarantee-container'>
        <div className='mobile-guarantee-image'>
            <span className='mobile-h3-black' style={{marginLeft: 20, marginTop: 8}}>
            Гарантия
            </span>
        </div>
        <div className='mobile-guarantee-text'>
            <div className='mobile-guarantee-text-section'>
                <div className='mobile-guarantee-days'>
                    <span className='mobile-numbers-black'>
                    90
                    </span>
                    <span className='mobile-h3-black' style={{alignSelf: 'end'}}>
                    дней
                    </span>
                </div>
                <span className='mobile-text-1-black'>
                Гарантия на ремонт
                </span>
            </div>
            <div className='mobile-guarantee-text-section'>
                <div className='mobile-guarantee-days'>
                    <span className='mobile-numbers-black'>
                    30
                    </span>
                    <span className='mobile-h3-black' style={{alignSelf: 'end'}}>
                    дней
                    </span>
                </div>
                <span className='mobile-text-1-black'>
                Гарантия на ремонт после жидкости
                </span>
            </div>

        </div>
    </div>
  )
}

export default MobileGuarantee
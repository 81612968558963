import React from 'react'
import "./MobileAboutUs.css"
import '../../../styles/typography.css'

function MobileAboutUs() {
  return (
    <div className='mobile-about-us-container'>
        <span className='mobile-h3-black' style={{display: 'block'}}>
        Работаем более 20 лет
        </span>
        <div className='mobile-about-us-text-section'>
            <span className='mobile-text-1-black'>
            Проводим коммерческий ремонт и обслуживаем технику Apple по лучшим стандартам компании. Все специалисты сертифицированы. При проведении ремонтов, мы используем надёжные инструменты и запасные части. Стремимся к самым высоким стандартам обслуживания.
            </span>
            <span className='mobile-text-1-black'>
            C 2003 года — региональное подразделение Apple IMC Russia в Екатеринбурге и сервис по ремонту Mac и iPod на его базе.<br /><br />
            C 2009 года — авторизованный сервисный центр (АСЦ) Apple.<br /><br />
            С 2017 года — Apple Premium Service Provider.
            </span>
            <span className='mobile-text-2-grey'>
            *** В Октябре 2024 года Apple прекратила деятельность АСЦ в РФ в связи с ограничениями на поставку запчастей для обслуживания техники в РФ. Это означает, что ремонт по гарантии сейчас не доступен.
            </span>
        </div>
    </div>
  )
}

export default MobileAboutUs
import React from 'react'
import "./MobileBackupCopy.css"
import '../../../styles/typography.css'

import iphoneImage from "../../../img/iphone.svg";
import ipadImage from "../../../img/ipad.svg";
import macbookImage from "../../../img/macbook.svg";
import imacImage from "../../../img/imac.svg";

function MobileBackupCopy() {
  return (
    <div className='mobile-backup-container'>
        <span className='mobile-backup-header mobile-h3-black'>
        Резервная копия защитит ваши фотографии, файлы и документы
        </span>
        <div className='mobile-backup-green-container'>
            <div className='mobile-backup-green-container-body'>
                <div className='mobile-backup-green-container-body-first'>
                    <span className='mobile-text-2-black'>
                    Чтобы не потерять ценную информацию на устройстве, важно всегда иметь резервную копию данных. Мы не удалим данные с устройства, без вашего согласия. Но в некоторых случаях нужно вернуть устройство к заводским настройкам. В этом случае готовая резервная копия значительно сэкономит время на ремонт.
                    </span>
                    <span className='mobile-text-2-black'>
                    А лучше застраховать себя от безвозвратной пропажи информации в случае утери, кражи устройства или невосстановимой поломки. Сделайте резервную копию, так ваши файлы и документы будут надежно защищены.
                    </span>
                    <a href='https://support.apple.com/ru-ru/118426' className='mobile-button-black'>
                    Как сделать резервную копию
                    </a>
                </div>
                <div className='mobile-backup-green-container-body-first'>
                    <div className='mobile-backup-green-container-body-second'>
                        <div className='mobile-backup-green-container-body-third'>
                            <div className='mobile-backup-icons-container'>
                                <img src={iphoneImage} alt='iphone' style={{width: 25, height: 'auto', alignSelf: 'end' }}/>
                                <img src={ipadImage} alt='ipad' style={{width: 40, height: 'auto'}}/>
                            </div>
                            <span className='mobile-text-2-black'>
                            Для телефона и планшета в iCloud или на компьютере через iTunes. 
                            </span>
                        </div>
                        <div className='mobile-backup-green-container-body-third'>
                            <div className='mobile-backup-icons-container'>
                                <img src={macbookImage} alt='iphone' style={{width: 44, height: 'auto', alignSelf: 'end' }}/>
                                <img src={imacImage} alt='ipad' style={{width: 54, height: 'auto'}}/>
                            </div>
                            <span className='mobile-text-2-black'>
                            Для телефона и планшета в iCloud или на компьютере через iTunes. 
                            </span>
                        </div>
                    </div>
                    <div className='mobile-backup-black-line'></div>
                    <span className='mobile-text-2-black'>
                    Важно, чтобы на устройстве, куда вы копируете данные, было достаточно свободного места. После создания резервной копии должно остаться ещё как минимум 20% не занятого пространства на диске.
                    </span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MobileBackupCopy
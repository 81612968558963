import React, {useState, useEffect} from 'react';
import './App.css';
import Header from './components/header/Header';
import MainInfo from './components/MainInfo/MainInfo';
import AboutUs from './components/AboutUs/AboutUs';
import Rewiews from './components/Reviews/Rewiews';
import OurActions from './components/OurActions/OurActions';
import BackupCopy from './components/BackupCopy/BackupCopy';
import Guarantee from './components/Guarantee/Guarantee';
import Location from './components/Location/Location';
import Footer from './components/Footer/Footer';

import MobileHeader from './components/Mobile/MobileHeader/MobileHeader';
import MobileMainInfo from './components/Mobile/MobileMainInfo/MobileMainInfo';
import MobileAboutUs from './components/Mobile/MobileAboutUs/MobileAboutUs';
import MobileReviews from './components/Mobile/MobileReviews/MobileReviews';
import MobileOurActions from './components/Mobile/MobileOurActions/MobileOurActions';
import MobileBackupCopy from './components/Mobile/MobileBackupCopy/MobileBackupCopy';
import MobileGuarantee from './components/Mobile/MobileGuarantee/MobileGuarantee';
import MobileLocation from './components/Mobile/MobileLocation/MobileLocation';
import MobileFooter from './components/Mobile/MobileFooter/MobileFooter';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      console.log('Current width:', window.innerWidth);
      setIsMobile(window.innerWidth <= 768);
    };

    // Подписываемся на событие изменения размера
    window.addEventListener('resize', handleResize);

    // Отписываемся при размонтировании
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="App">
      {isMobile ? (
        <>
          {/* Набор компонентов для мобильной версии */}
          <MobileHeader />
          <MobileMainInfo />
          <MobileAboutUs />
          <MobileReviews />
          <MobileOurActions />
          <MobileBackupCopy />
          <MobileGuarantee />
          <MobileLocation />
          <MobileFooter />
        </>
      ) : (
        <>
          {/* Набор компонентов для десктопной версии */}
          <Header />
          <MainInfo />
          <AboutUs />
          <Rewiews />
          <OurActions />
          <BackupCopy />
          <Guarantee />
          <Location />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;

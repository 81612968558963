import React from 'react'
import "./Footer.css"
import '../../styles/typography.css'
import authImageWhite from "../../img/authwhite.svg";
import logoImageWhite from "../../img/logowhite.svg";
import tgWhite from "../../img/tgwhite.svg"
import whatsappWhite from "../../img/whatsappwhite.svg"

function Footer() {
  return (
    <div className='footer-container'>
        <div className='footer-body'>
            <div className='footer-main'>
                <div className='footer-main-horizontal'>
                    <span className='h1-white'>
                    контакты
                    </span>
                    <img src={authImageWhite} alt='Авторизованный сервисный центр' style={{alignSelf: 'flex-start'}}/>
                </div>

                <div className='footer-secondary-horizontal'>
                    <img src={logoImageWhite} alt='Логотип' style={{alignSelf: 'flex-start'}} />
                    <div className='footer-info-container'>
                        <div className='footer-info-piece'>
                            <span className='h4-green'>
                            всегда<br/> на связи
                            </span>
                            <div className='footer-info-piece-piece'>
                                <div className='footer-link-container'>
                                    <img src={tgWhite} alt=''/>
                                    <a className='button-1-white' href='https://t.me/+79226043309'>Telegram</a>
                                </div>
                                <div className='footer-link-container'>
                                    <img src={whatsappWhite} alt=''/>
                                    <a className='button-1-white' href='https://api.whatsapp.com/send?phone=79226043309'>Whatsapp</a>
                                </div>
                                <span className='button-1-white'>
                                +7 (922) 604 33 09
                                </span>
                            </div>
                        </div>

                        <div className='footer-info-piece'>
                            <span className='h4-green'>
                            ПН-СБ<br/> с 10:00 до 20:00
                            </span>
                            <div className='footer-info-piece-piece'>
                                <span className='button-1-white'>
                                Екатеринбург, ул. Крылова, 27
                                </span>
                                <span className='e-mail'>
                                admin@alster-service.ru
                                </span>
                                <span className='button-1-white'>
                                +7 (343) 278 71 78
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-white-line' style={{marginTop: 24, marginBottom: 24}}></div>
            <a className='button-1-white' href=''>Политика конфиденциальности</a>
        </div>

    </div>
  )
}

export default Footer
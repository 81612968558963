import React, {useState} from 'react'
import { CSSTransition } from 'react-transition-group'
import "./Rewiews.css"
import '../../styles/typography.css'
import RewiewCard from './RewiewCard/RewiewCard'
import arrowLeft from "../../img/arrowleft.svg";
import arrowRight from "../../img/arrowright.svg";
import reviewsData from '../../data/reviews.json';

function Rewiews() {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [inProp, setInProp] = useState(true); // Управляем видимостью компонента
  const [direction, setDirection] = useState(''); // Направление анимации

  const handleNext = () => {
    setDirection('right'); // Устанавливаем направление вправо
    setInProp(false); // Убираем текущую карточку
    setTimeout(() => {
      setCurrentReviewIndex((prevIndex) =>
        prevIndex === reviewsData.length - 1 ? 0 : prevIndex + 1
      );
      setInProp(true); // Показываем новую карточку
    }, 300); // Длительность анимации
  };

  const handlePrev = () => {
    setDirection('left'); // Устанавливаем направление влево
    setInProp(false); // Убираем текущую карточку
    setTimeout(() => {
      setCurrentReviewIndex((prevIndex) =>
        prevIndex === 0 ? reviewsData.length - 1 : prevIndex - 1
      );
      setInProp(true); // Показываем новую карточку
    }, 300); // Длительность анимации
  };

  const currentReview = reviewsData[currentReviewIndex];
  
  return (
    <div className='rewiews-body'>
        <span className='h3-black rewiews-header'>
        Отзывы
        </span>
        <div className='rewiews-container'>
            <div className='arrows-container'>
                <img src={arrowLeft} alt='Previous' onClick={handlePrev}/>
                <img src={arrowRight} alt='Next'onClick={handleNext}/>
            </div>
            
            <CSSTransition
                in={inProp} // Управляем видимостью
                timeout={300} // Длительность анимации
                classNames={`slide-${direction}`}
                unmountOnExit
            >
                <div>
                    <RewiewCard
                    username={currentReview.username}
                    review={currentReview.review}
                    />
                </div>
            </CSSTransition>

            <a href='https://yandex.ru/maps/org/alster_servis/1231327985/reviews/?ll=60.571041%2C56.831897&z=14' className='button-1-black'>Читать все отзывы на Яндекс</a>
        </div>
    </div>
  )
}

export default Rewiews
import React from 'react'
import "./AboutUs.css"
import '../../styles/typography.css'

function AboutUs() {
  return (
    <div className='container'>
        <span className='h3-black section-header'>
        Работаем более 20 лет
        </span>
        <div className='text-container'>
          <span className='text-1-black'>
          Проводим коммерческий ремонт и обслуживаем технику Apple по лучшим стандартам компании. Все специалисты сертифицированы. При проведении ремонтов, мы используем надёжные инструменты и запасные части. Стремимся к самым высоким стандартам обслуживания.
          </span>
          <span className='text-1-black'>
          C 2003 года — региональное подразделение Apple IMC Russia в Екатеринбурге и сервис по ремонту Mac и iPod на его базе.<br /><br />
          C 2009 года — авторизованный сервисный центр (АСЦ) Apple.<br /><br />
          С 2017 года — Apple Premium Service Provider.
          </span>
          <span className='text-3-grey'>
          *** В Октябре 2024 года Apple прекратила деятельность АСЦ в РФ в связи с ограничениями на поставку запчастей для обслуживания техники в РФ. Это означает, что ремонт по гарантии сейчас не доступен.
          </span>
        </div>
    </div>
  )
}

export default AboutUs
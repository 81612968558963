import React from 'react'
import "./header.css"
import logoImage from "../../img/logo.svg";
import '../../styles/typography.css'


function Header() {
  return (
    <div className='header'>
      <img
        src={logoImage}
        alt='Логотип'
        className='logo'
      />

      <div className='h-container'>
        <div className='telephone-container'>
          <span className='telephone text-1-black' style={{order: 0}}>
          +7 (922) 604 33 09
          </span>
          <span className='telephone text-1-black' style={{order: 1}}>
          +7 (343) 278 71 78
          </span>
        </div>
        <span className='text-1-black adress'>
        Екатеринбург, ул. Крылова, 27<br />
        ПН-СБ 10:00-20:00
        </span>
      </div>
    </div>
  )
}

export default Header;
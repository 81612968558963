import React from 'react'
import "./BackupCopy.css"
import '../../styles/typography.css'

import iphoneImage from "../../img/iphone.svg";
import ipadImage from "../../img/ipad.svg";
import macbookImage from "../../img/macbook.svg";
import imacImage from "../../img/imac.svg";

function BackupCopy() {
  return (
    <div className='backup-body'>
        <div className='backup-card'>
            <span className='h3-black' style={{marginTop: 50}}>
            Резервная копия защитит ваши фотографии, файлы и документы
            </span>
            <div className='instruction-body'>
                <div className='instruction-first'>
                    <div className='instruction-first-horizontal'>
                        <span className='text-3-black'>
                        Чтобы не потерять ценную информацию на устройстве, важно всегда иметь резервную копию данных. Мы не удалим данные с устройства, без вашего согласия. Но в некоторых случаях нужно вернуть устройство к заводским настройкам. В этом случае готовая резервная копия значительно сэкономит время на ремонт.
                        </span>
                        <span className='text-3-black'>
                        А лучше застраховать себя от безвозвратной пропажи информации в случае утери, кражи устройства или невосстановимой поломки.<br /><br /> Сделайте резервную копию, так ваши файлы и документы будут надежно защищены.
                        </span>
                    </div>
                    <a href='https://support.apple.com/ru-ru/118426' className='button-1-black'>
                    Как сделать резервную копию
                    </a>
                </div>
                <div className='instruction-second'>
                    <div className='instruction-second-horizontal'>
                        <div className='device-type-container'>
                            <div className='device-image-container'>
                                <img src={iphoneImage} alt='iphone'/>
                                <img src={ipadImage} alt='ipad'/>
                            </div>
                            <span className='text-1-black'>
                            Для телефона и планшета в iCloud или на компьютере через iTunes.
                            </span>
                        </div>
                        <div className='device-type-container'>
                            <div className='device-image-container'>
                                <img src={macbookImage} alt='macbook'/>
                                <img src={imacImage} alt='imac'/>
                            </div>
                            <span className='text-1-black'>
                            Для компьютера и ноутбука на внешний диск или Apple Time Capsule с помощью утилиты Apple Time Machine. 
                            </span>
                        </div>
                    </div>
                    <div className='instruction-black-line'></div>
                    <span className='text-3-black' style={{width: 590, marginBottom: 50}}>
                    Важно, чтобы на устройстве, куда вы копируете данные, было достаточно свободного места. После создания резервной копии должно остаться ещё как минимум 20% не занятого пространства на диске.
                    </span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BackupCopy
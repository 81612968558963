import React from 'react'
import "./MobileMainInfo.css"
import '../../../styles/typography.css'
import authImageWhite from "../../../img/authwhite.svg";
import telegramIcon from "../../../img/tg.svg";
import whatsappIcon from "../../../img/whatsapp.svg";

function MobileMainInfo() {
  return (
    <div className='mobile-main-info-container'>
        <div className='mobile-main-info-black-card'>
            <img src={authImageWhite} alt='Авторизация' className='mobile-main-info-auth'/>
            <span className='mobile-h1-white mobile-main-info-big-text'>
            сервисный центр Apple в Екатеринбурге
            </span>
            <span className='mobile-text-1-white mobile-main-info-text'>
            Ремонт и обслуживание Macbook, iMac, iPhone и другой техники Apple
            </span>
            <div className='mobile-main-info-green-card'>
                <div className='mobile-main-info-green-card-text'>
                    <span className='mobile-numbers-black'>
                    24/7
                    </span>
                    <span className='mobile-text-1-black' style={{alignSelf: 'center'}}>
                    на связи<br /> в мессенджерах
                    </span>
                </div>
                <div className='mobile-main-info-green-card-buttons'>
                    <a href='https://t.me/+79226043309' className='mobile-main-info-button'>
                        <img src={telegramIcon} alt='tg' className='mobile-button-icon'/>
                        <span className='button__text' style={{fontSize: 10}}>Telegram</span>
                    </a>
                    <a href='https://api.whatsapp.com/send?phone=79226043309' className='mobile-main-info-button'>
                        <img src={whatsappIcon} alt='whatsapp' className='mobile-button-icon'/>
                        <span className='button__text' style={{fontSize: 10}}>Whatsapp</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MobileMainInfo
import React from 'react'
import "./MobileReviews.css"
import MobileReviewCard from './MobileReviewCard/MobileReviewCard.js'
import reviewsData from '../../../data/reviews.json';
import '../../../styles/typography.css'

export default function MobileReviews() {
  return (
    <div className='mobile-reviews'>
        <span className='mobile-h3-black'>
        Отзывы
        </span>
        <div className='mobile-reviews-carousel'>
          {reviewsData.map((review, index) => (
            <MobileReviewCard
              key={index}
              username={review.username}
              review={review.review}
            />
          ))}
        </div>
        <a href='https://yandex.ru/maps/org/alster_servis/1231327985/reviews/?ll=60.571041%2C56.831897&z=14' className='mobile-button-black'>Читать все отзывы на Яндекс</a>
    </div>
  )
}

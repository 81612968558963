import React from 'react'
import "./MobileReviewCard.css"
import starImage from "../../../../img/star.svg";

function MobileReviewCard({ username, review }) {
  return (
    <div className='mobile-review-card-body'>
        <div className='mobile-review-card-header'>
            <span className='mobile-text-3-black'>{username}</span>
            <div className='mobile-stars-container'>
                <img src={starImage} alt=''/>
                <img src={starImage} alt=''/>
                <img src={starImage} alt=''/>
                <img src={starImage} alt=''/>
                <img src={starImage} alt=''/>
            </div>
        </div>
        <span className='mobile-text-2-black'>{review}</span>
    </div>
  )
}

export default MobileReviewCard
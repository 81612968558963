import React from 'react'
import "./Guarantee.css"
import '../../styles/typography.css'

function Guarantee() {
  return (
    <div className='guarantee-body'>
        <div className='guarantee-image'>
            <span className='h3-black' style={{marginLeft: 100}}>
            Гарантия
            </span>
        </div>
        <div className='guarantee-text-container'>
            <div className='guarantee-type'>
                <div className='days-text'>
                    <span className='days-number'>
                    90
                    </span>
                    <span className='h4-black' style={{alignSelf: 'flex-end'}}>
                    дней
                    </span>
                </div>
                <span className='text-1-black'>
                Гарантия на ремонт
                </span>
            </div>
            <div className='guarantee-type'>
                <div className='days-text'>
                    <span className='days-number'>
                    30
                    </span>
                    <span className='h4-black' style={{alignSelf: 'flex-end'}}>
                    дней
                    </span>
                </div>
                <span className='text-1-black'>
                Гарантия на ремонт<br /> после жидкости
                </span>
            </div>
        </div>
    </div>
  )
}

export default Guarantee
import React from 'react'
import "./Location.css"
import '../../styles/typography.css'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'

function Location() {
  return (
    <div className='location-body'>
        <span className='h3-black' style={{marginLeft: 100}}>
        Как добраться
        </span>
        <div className='location-container'>
            <div className='map-container'>
            <YMaps>
                <Map defaultState={{ center: [56.83227, 60.571037], zoom: 15 }} width="100%" height="100%">
                <Placemark geometry={[56.83227, 60.571037]} />
                </Map>
            </YMaps>
            </div>
            <div className='location-text-container'>
                <div className='location-text-section'>
                    <span className='text-2-black'>
                    Адрес
                    </span>
                    <span className='text-1-black'>
                    ул. Крылова, 27<br />деловой центр "Крыловский"<br /> напротив Екатеринбург Арена
                    </span>
                </div>
                <div className='location-text-section'>
                    <span className='text-2-black'>
                    График работы
                    </span>
                    <span className='text-1-black'>
                    ПН-СБ с 10:00 до 20:00<br /> ВС выходной
                    </span>
                </div>
                <div className='location-text-section'>
                    <span className='text-2-black'>
                    Горячая линия
                    </span>
                    <span className='text-1-black'>
                    +7 (922) 604 33 09
                    </span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Location
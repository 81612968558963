import React from 'react'
import "./MainInfo.css"
import '../../styles/typography.css'
import authImageWhite from "../../img/authwhite.svg";
import telegramIcon from "../../img/tg.svg";
import whatsappIcon from "../../img/whatsapp.svg";

function MainInfo() {
  return (
    <div className='black-container'>
        <span className='h1-white main-text'>
        сервисный<br />
        центр apple<br />
        в екатеринбурге<br />
        </span>
        <img
        src={authImageWhite}
        alt='Авторизованный сервисный центр'
        className='auth-image'
        />
        <span className='text-1-white secondary-text'>
        Ремонт и обслуживание Macbook, iMac, iPhone<br />
        и другой техники Apple
        </span>
        <div className='green-container'>
            <span className='numbers-text time'>
            24/7
            </span>
            <span className='text-1-black connect'>
            на связи<br />
            в мессенджерах
            </span>
            <a href='https://t.me/+79226043309' class="button" style={{marginRight: 35}}>
                <img src={telegramIcon} alt="Telegram Icon" class="button__icon" />
                <span class="button__text">Telegram</span>
            </a>
            <a href='https://api.whatsapp.com/send?phone=79226043309' class="button">
                <img src={whatsappIcon} alt="Whatsapp Icon" class="button__icon" />
                <span class="button__text">Whatsapp</span>
            </a>
        </div>
    </div>
  )
}

export default MainInfo
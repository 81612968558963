import React from 'react'
import "./MobileHeader.css"
import logoImage from "../../../img/logo.svg";
import '../../../styles/typography.css'

// TODO Сделать номер телефона кликабельным
function MobileHeader() {
  return (
    <div className='mobile-header'>
        <img src={logoImage} alt='logo' className='mobile-logo'/>
        <span className='mobile-text-1-black'>
        ул. Крылова, 27<br />+7 (922) 604 33 09
        </span>
    </div>
  )
}

export default MobileHeader
import React from 'react'
import "./MobileFooter.css"
import '../../../styles/typography.css'
import logoImageWhite from "../../../img/logowhite.svg";
import tgWhite from "../../../img/tgwhite.svg"
import whatsappWhite from "../../../img/whatsappwhite.svg"

function MobileFooter() {
  return (
    <div className='mobile-footer-container'>
        <div className='mobile-footer-body'>
            <div className='mobile-footer-horizontal'>
                <div style={{flex: 1}}>
                    <img src={logoImageWhite} alt='logo' className='mobile-footer-logo'/>
                </div>
                <span className='mobile-footer-white' style={{flex: 1}}>
                контакты
                </span>
            </div>
            <div className='mobile-footer-horizontal'>
                <div className='mobile-footer-piece'>
                    <span className='mobile-h4-green'>
                    всегда<br />на связи
                    </span>
                    <div>
                        <div className='mobile-footer-link-container'>
                                <img src={tgWhite} alt='' className='mobile-footer-link-logo'/>
                                <a className='mobile-button-white' href='https://t.me/+79226043309'>Telegram</a>
                        </div>
                        <div className='mobile-footer-link-container' style={{marginTop: 7}}>
                                <img src={whatsappWhite} alt='' className='mobile-footer-link-logo'/>
                                <a className='mobile-button-white' href='https://api.whatsapp.com/send?phone=79226043309'>Whatsapp</a>
                        </div>
                        <span className='mobile-button-white' style={{display: 'block', marginTop: 7}}>
                        +7 (922) 604 33 09
                        </span>
                    </div>
                </div>

                <div className='mobile-footer-piece'>
                    <span className='mobile-h4-green'>
                    ПН-СБ<br />с 10:00 до 20:00
                    </span>
                    <div>
                        <span className='mobile-button-white' style={{display: 'block'}}>
                        Екатеринбург, ул. Крылова, 27
                        </span>
                        <span className='mobile-button-white' style={{display: 'block', marginTop: 7}}>
                        admin@alster-service.ru
                        </span>
                        <span className='mobile-button-white' style={{display: 'block', marginTop: 7}}>
                        +7 (343) 278 71 78
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className='mobile-footer-white-line'></div>
        <a className='mobile-button-white' href=''>Политика конфиденциальности</a>
    </div>
  )
}

export default MobileFooter
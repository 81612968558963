import React from 'react'
import "./RewiewCard.css"
import '../../../styles/typography.css'
import starImage from "../../../img/star.svg";

function RewiewCard({ username, review }) {
  return (
    <div className='rewiew-card-body'>
        <div className='rewiev-card-header'>
            <span className='text-2-black'>{username}</span>
            <div className='star-container'>
                <img src={starImage} alt=''/>
                <img src={starImage} alt=''/>
                <img src={starImage} alt=''/>
                <img src={starImage} alt=''/>
                <img src={starImage} alt=''/>
            </div>
        </div>
        <span className='text-4-black'>{review}</span>
    </div>
  )
}

export default RewiewCard